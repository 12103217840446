import React, {
	FunctionComponent,
	MutableRefObject,
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	createNavigatorFactory,
	Link,
	ParamListBase,
	Route,
	StackNavigationState,
	useNavigationBuilder,
} from "@react-navigation/native";
import Assets from "@assets";
import { Text, StyleProp, ViewStyle, ImageStyle } from "react-native";
import * as RNN from "@react-navigation/native";
import {
	StackNavigationEventMap,
	StackNavigationOptions,
	StackScreenProps,
} from "@react-navigation/stack";
import * as rxjs from "rxjs";

/** careful with `style` -- only `tintColor` is tested to work  **/
export function BackToHomeButton({
	to,
	style,
}: {
	to?: { screen: string; params?: any };
	style?: StyleProp<ViewStyle | ImageStyle>;
}) {
	return (
		<Link to={{ screen: to?.screen ? to?.screen : "Home", params: to?.params }} style={style}>
			<Assets.LeftArrow
				style={{
					// centering it is tricky because the progress bar is separate
					margin: 5,
					...(style && (style as any)?.tintColor ? { tintColor: (style as any)?.tintColor } : {}),
				}}
			/>
		</Link>
	);
}

type NavigationBuilderRetType = ReturnType<typeof useNavigationBuilder>;
export type NavigatorNavigationType = NavigationBuilderRetType["navigation"];
// TODO what the hell is this magic
type NavigatorStateType = NavigationBuilderRetType["state"];

// < ParamList extends {} = ReactNavigation.RootParamList >

import type { NavigationHelpers } from "@react-navigation/native";
import { isFunction } from "lodash";
import { Subscribable } from "relay-runtime";

export type NavigatorBodyParams<ParamList extends ParamListBase> = {
	children: JSX.Element[];
	// the best way to get to NavigationHelpersCommon<ParamListBase, any>
	navigation: Omit<NavigationHelpers<ParamList, {}>, "emit">;
	/** use this instead of `useRoute()` to get the inside route state **/
	route: Route<string>;
};

/**
 * used mainly to recover key params when browser reloads
 * URL params are passed to the innermost route and any upper route needs
 * to recursively find them
 */
export function getSubRouteParams(
	state: RNN.PartialState<RNN.NavigationState<RNN.ParamListBase>> | null,
): object | undefined {
	if (state != null) {
		const croute = state.routes[state.index ?? 0];
		// croute.params is object | undefined soo...
		return croute.params ?? getSubRouteParams(croute.state ?? null);
	} else {
		return undefined;
	}
}

function VanillaNavigator<ParamList extends ParamListBase>({
	children,
	navigationRef,
	stateRef,
	body,
	initialRouteName,
}: {
	children: JSX.Element[];
	navigationRef?: MutableRefObject<NavigatorNavigationType | null>;
	stateRef?: MutableRefObject<NavigatorStateType | null>;
	body?: React.FC<NavigatorBodyParams<ParamList>>;
	initialRouteName: string;
}) {
	//
	const { state, descriptors, navigation, NavigationContent } = useNavigationBuilder(
		RNN.StackRouter,
		{ children, initialRouteName },
	);

	if (navigationRef) navigationRef.current = navigation;
	if (stateRef) stateRef.current = state;

	const currentState = state.routes[state.index];
	const currentScreen = descriptors[currentState.key];

	return (
		<NavigationContent>
			{body
				? React.createElement(body, {
						navigation,
						// important -- this is the only way to get the inside route
						// using `useRoute()` in the body returns the parent route
						route: currentState,
						children: [currentScreen.render()],
					})
				: currentScreen.render()}
		</NavigationContent>
	);
}

/** use `VanillaNavigatorFactory<YourRoutesParamMap>`
	 see the note at the bottom of the section https://reactnavigation.org/docs/typescript/#type-checking-the-navigator
 **/
export const VanillaNavigatorFactory = createNavigatorFactory<
	StackNavigationState<ParamListBase>,
	StackNavigationOptions,
	StackNavigationEventMap,
	typeof VanillaNavigator
>(VanillaNavigator);

export function LoadingScreen<T>(
	check: { (_: T): boolean },
	component: React.FunctionComponent<T>,
) {
	return (props: T) => {
		if (check(props)) {
			return React.createElement(component as any, props as any);
		} else {
			return <Text>...LOADING...</Text>;
		}
	};
}

function GenericLoadingView() {
	return <Text>...loading...</Text>;
}
/**
 * @return component tracks the observable's values as it's output
 **/
export function useObservableToComponent<FP extends {}>(
	ob: rxjs.Observable<ReactElement<any, any> | FunctionComponent<FP> | null>,
) {
	return function (props: FP) {
		const elmO = withObservable(ob).pipe(
			rxjs.map((r) => {
				if (isFunction(r)) {
					return React.createElement(r, { ...props });
				} else {
					return r;
				}
			}),
		);
		const ret = useObservable(elmO);

		return ret;
	};
}

/**
 * Convert normal component to screen component.

 * @param component - the component, props type is inferred and
 * matched. Error message when wrong not very self-explanatory. When
 * no R and RN are provided, it's the union of all route params
	 
 * @param  propsReducer - optional reducer to modify the props. NOTE: by
 * design it's re-evaluated at every render so you can use latest
 * render scope in it. Bit dirty design, but helps a lot.
	 
 * The reducer allows using new, idiomatic pattern to refresh screen
 * components. Not sure how happy the react-navigation team will be, 
 * but I hate to render a screen, then useEffect to capture async
 * value which at this point can be communicated only via `setParams`,
 * `navigate` or similar and cause flickers. Not to mention this is
 * absolutely un-idiomatic. Alternative is to pass the async value to
 * the screen which then has to handle unrealized values, etc. 
	 
 */
export function useScreenAdapter<
	R extends ParamListBase,
	RN extends keyof R = keyof R,
	SSP extends StackScreenProps<R, RN> = StackScreenProps<R, RN>,
	T extends R[RN] | undefined = R[RN],
>(
	component: FunctionComponent<T>,
	propsReducer: (p: T) => T = (x: T) => x,
): FunctionComponent<SSP> {
	const ret = useCallback(
		(input: SSP) => {
			const props = input.route.params;
			const res = useMemo(
				() =>
					props
						? React.createElement(
								component as FunctionComponent<{}>,
								propsReducer(props as T) as never,
							)
						: null,
				[props],
			);
			return res;
		},
		[component, propsReducer],
	);
	return ret;
}

/**
 * subscribes to the observable and returns lates value
 * employs `useState` and triggers re-render
 **/
export function useObservable<T>(ob: rxjs.Subscribable<T> | rxjs.Observable<T> | undefined | null) {
	const [value, setValue] = useState<T>();

	useMemo(() => {
		//		if (ob != null)subscription= ob.subscribe({ next: setValue });
	}, [ob]);

	useEffect(() => {
		if (ob != null) {
			const subscription = ob.subscribe({ next: setValue });

			return () => {
				subscription.unsubscribe();
			};
		}
	}, [ob]);

	return value;
}

export function useLoadingViewO<D, T extends {}>(
	ob: rxjs.Observable<D>,
	component: React.FunctionComponent<T>,
	paramsFn: (p: T, d: D) => T,
	loadingComponent: React.FunctionComponent<unknown> = GenericLoadingView,
): React.FunctionComponent<T> {
	const ret = useCallback(
		(p: T) => {
			const d = useObservable(ob);

			console.debug(`useLoadingView observable:`, d);

			const lcc = useCallback(
				() => React.createElement(loadingComponent, p),
				[loadingComponent, p],
			);

			const theC = useCallback(() => {
				// doing it this way so that `paramsFn` can use hooks
				const params: T = paramsFn(p, d!);
				return React.createElement(component, params);
			}, [p, d]);

			return d == null ? React.createElement(lcc, {}) : React.createElement(theC, {});
		},
		[ob, component],
	);
	return ret;
}

/**
 * replaces the need to operate with Observables always in `useEffect` or similar
 * after wrapping the original observable, you can invoke `pipe` and other functions
 * on the wrapper as usual, having guarantee side effects will run only once
 **/
export function withObservable<T>(o: rxjs.Observable<T>) {
	const ret = useRef<rxjs.Observable<T> | null>(null);

	// TODO dispose ret.current?

	useMemo(() => {
		ret.current = null;
	}, [o]);

	if (ret.current == null) {
		// fake
		ret.current = new rxjs.Observable<T>((_: rxjs.Subscriber<T>) => {});
		// first invocation with this value, return the real thing
		return o.pipe(rxjs.skip(1));
	} else {
		// we're in re-render, return fake
		return ret.current;
	}
}

/** suitable when the subscriber should be executed inside `useEffect`
	 @param behavioral USE WITH CAUTION because first listener will be
	 triggered in the calling context which may be render!
 **/
export function useValueObservable(value: any, behavioral: boolean = false) {
	//
	const subject = useMemo(() => new rxjs.BehaviorSubject(behavioral ? value : undefined), []);
	// I'm gonna un-behavior the origininal subject in order to
	// have every call including the first inside the `useEffect`
	const ret = useMemo(() => subject.pipe(rxjs.filter((v) => v !== undefined)), [subject]);

	useEffect(() => {
		// our guarantee about the calling location
		subject.next(value);

		return () => {
			// hm, this gets unmounted much more frequently than I expected
			//subject.complete();
		};
	}, [value]);

	return ret;
}

//export function subToSubj<T>(_: Subscribable<T>, __?: T): rxjs.Subject<T>;
//export function subToSubj<T>(_: null | undefined, __?: unknown): undefined;

/**
 * converts subscribable to subject. when `initial` is provided it's behaviorsubject, otherwise plain subject
	 
 **/
export function subToSubj<T extends {} | null, S extends Subscribable<T> | null | undefined>(
	sub: S,
	initial?: T,
): S extends {} ? rxjs.Subject<T> : undefined {
	//rxjs.Observable<T> | undefined
	if (sub != null) {
		const subj =
			initial !== undefined ? new rxjs.BehaviorSubject<T>(initial) : new rxjs.Subject<T>();

		sub.subscribe({
			// TODO catch !
			next:
				// you can't use directly subj's next, relay does some magic and crashes
				(v) => subj.next(v),
		});
		// @ts-ignore see https://stackoverflow.com/questions/48808014/typescript-conditional-return-value-type
		return subj;
	} else {
		// @ts-ignore
		return undefined;
	}
}

/**
	 Adapter to enable normal components to be used as screen components. returns component function that takes route params and passes them to the original CF as props.
 **/
export function RouteAdapter<T extends (...args: any) => any>(component: T): T {
	const Ret = ({ route }: Parameters<T>) => {
		//const route = useRoute();
		return React.createElement(component, { ...route?.params } as Parameters<T>);
	};
	return Ret as T;
}
