import React, { useCallback, useRef } from "react";
import {
	Pressable,
	PressableProps,
	PressableStateCallbackType,
	StyleProp,
	ViewStyle,
} from "react-native";
import { composites } from "@styles";

export default function Button({
	nativeID,
	onPress,
	onPressIn,
	onPressOut,
	onLongPress,
	style,
	children,
	disabled,
}: PressableProps) {
	const styleFn = (state: PressableStateCallbackType) => {
		const inputStyle = typeof style == "function" ? style(state) : style;
		return [
			composites.button,
			state.pressed ? { opacity: 0.5 } : null,
			inputStyle as unknown as StyleProp<ViewStyle>,
		];
	};

	// TODO some sort of shared press context
	const pressRef = useRef(null);

	const opo = useCallback(
		(e: any) => {
			// TODO type defs seem incomplete, this will be different on native
			if (e.nativeEvent.type != "blur" && onPressOut) {
				// pressOut sends blur event when navigatin away...sick
				onPressOut(e);
			}
		},
		[onPressOut],
	);

	return (
		<Pressable
			style={styleFn}
			onPressOut={opo}
			{...{
				onPress,
				onPressIn,
				onLongPress,

				nativeID,
				disabled,
			}}
		>
			{children}
		</Pressable>
	);
}
