import * as React from "react";

import {
	getStateFromPath,
	getPathFromState,
	useNavigationBuilder,
	createNavigatorFactory,
	StackRouter,
	useNavigation,
} from "@react-navigation/native";

import { View, useWindowDimensions } from "react-native";

// TODO hate next 2 deps
import gStyles from "../globalStyle";
import styles from "./styles";

import { BackToHomeButton } from "@common/index";

function HeaderComponent({ routeDescriptors, routerState }) {
	//	console.warn("descriptors", routeDescriptors, routerState);

	const currentRoute = routerState.routes[routerState.index];
	// this is fallback, can't be made to work as normal back so don't try
	const defaultBack = routeDescriptors[currentRoute.key]?.options?.back;

	const backRouteDescriptor =
		routerState.index == 0
			? defaultBack && {
					route: { name: defaultBack.screen, params: currentRoute.params },
					options: { title: defaultBack.title },
			  }
			: routeDescriptors[routerState.routes[routerState.index - 1].key];

	const backText =
		backRouteDescriptor && (backRouteDescriptor.options.title || backRouteDescriptor.route.name);

	return backRouteDescriptor ? (
		<View
			style={[
				gStyles.row,
				styles.headerWrapper,
				{
					backgroundColor: "transparent",
					alignItems: "center",
				},
			]}
		>
			<BackToHomeButton
				to={{
					screen: backRouteDescriptor.route.name,
					params: backRouteDescriptor.route.params,
				}}
			/>
		</View>
	) : null;
}

function MyRouter(...args) {
	let res = StackRouter.apply(null, args);
	console.log("initialized my router", res);
	const oGetStateForAction = res.getStateForAction.bind(res);
	res.getStateForAction = (...args) => {
		const [srcState, artion, wth] = args;
		let ns = oGetStateForAction.apply(res, args);
		console.warn("get state for action", args, ns);

		return ns;
	};
	return res;
}

export const VimeoControlsContext = React.createContext({});
export const VimeoGlobalPlayer = React.createContext(null);

const ReferalIdParam = "usrrefid";

function GetUser({}) {
	return null;
}

function checkReload(headShaRef) {
	fetch("/headsha")
		.then((b) => b.text())
		.then((t) => {
			console.log("HEAD SHA", t);
			if (headShaRef.current) {
				if (headShaRef.current != t) {
					console.log("⚠️ update detected, reloading...");
					window.location.reload();
				}
			} else {
				headShaRef.current = t;
			}
		});
}
/**
 * reloads the whole page when new version deployment is detected
 **/
function Reloader() {
	const nav = useNavigation();

	const headSha = React.useRef(null);

	React.useEffect(() => {
		if (!headSha.current) {
			nav.addListener("state", () => {
				checkReload(headSha);
			});
			window.addEventListener("focus", () => {
				checkReload(headSha);
			});
		}
	});

	return null;
}

function MyNavigator({ initialRouteName, children, screenOptions, ...rest }) {
	const { state, descriptors, navigation, NavigationContent } = useNavigationBuilder(MyRouter, {
		initialRouteName,
		children,
		screenOptions,
	});

	//	console.log("navigator state:", state, descriptors, backBehavior);

	// TODO listen for updates
	//const windowHeight = Dimensions.get("window").height;
	const { height, width } = useWindowDimensions();
	const windowHeight = height;

	const [thePlayer, setThePlayer] = React.useState(null);

	console.log("-- root navigator render --");

	const currentRoute = state.routes[state.index];
	const currentScreen = descriptors[currentRoute.key];

	const prevUrlParams = React.useRef({});
	const prevUrl = React.useRef(null);
	const newUrl = toPath(state, {});

	// WARNING may be stale
	const profileRef = React.useRef({});

	if (prevUrl.current != newUrl) {
		//console.log ('>>>',prevUrlParams.current[ReferalIdParam])
		if (prevUrlParams.current[ReferalIdParam]) {
			// copy refid
			state.routes[state.index].params = state.routes[state.index].params || {};
			state.routes[state.index].params[ReferalIdParam] = prevUrlParams.current[ReferalIdParam];
		}
		const newParams = Object.assign({}, state.routes[state.index].params || {});
		delete newParams.fragment;

		console.log("new nav state", descriptors, state, prevUrl.current, " => ", newUrl);
		//console.log("prev params", prevUrlParams.current, " =>", newParams);
		//
		prevUrl.current = newUrl;
		prevUrlParams.current = newParams;
		// telemetry
	}

	return (
		<NavigationContent>
			<Reloader />
			<View style={[styles.topContainer]}>
				{/* TODO why this view in view? */}
				<View style={{ flex: 1 }}>
					{currentScreen.options.headerShown ? (
						<HeaderComponent routeDescriptors={descriptors} routerState={state} />
					) : null}
					<View
						style={{
							flex: 1,
							background: "transparent",
							alignSelf: "stretch",
							zIndex: 1,
						}}
						key="navigator's content frame"
					>
						{
							//
							currentScreen.render()
							//
						}
					</View>
				</View>
			</View>
		</NavigationContent>
	);
}

export const defaultNavigatorFactory = createNavigatorFactory(MyNavigator);

const [fromPath, toPath] = createMarshallers();

export const navContainerLinkingConfig = {
	// keep it even if empty to enable browser integration
	prefixes: [],

	config: {
		screens: {
			login: "login",
			"link-dispatch": {
				screens: {
					confirmemail: "link-dispatch/confirmemail",
					resetpwredirect: "link-dispatch/resetpwredirect",
				},
			},
		},
	},
	getStateFromPath: fromPath,
	//getPathFromState: toPath
};

function createMarshallers() {
	// old experiment
	return [
		function _getStateFromPath(path, options) {
			let newState = getStateFromPath(path, options);

			console.log("recovering navigator state from url", newState, path, options);

			// if no path in uri, no route
			if (newState) {
				console.assert(newState.routes.length == 1);

				/** move child route params up
				 * currently bit of a hack
				 */
				// TODO recurse
				// TODO state.routes can be more than 1 element? doubt it!
				if (newState.routes[0].state) {
					newState.routes[0].params = newState.routes[0].state.routes[0].params;
				}

				/**
				 * clean-up the URL parameters from serialized Objects. Normally
				 * parameters are exchanged via the navigation state and the
				 * serialized ones in the URL are ignored. BUT if we refresh the
				 * page, there's no state and props are deserialized and then we
				 * need to sanitize.
				 **/

				let fixedState = newState;
				let params = fixedState.routes[0].params;
				for (let k in params) {
					if (params[k] == "[object Object]") {
						delete params[k];
					} else if (
						Array.isArray(params[k]) &&
						params[k].length > 0 &&
						params[k][0] == "[object Object]"
					) {
						delete params[k];
					}
				}

				//				console.log('>> state', path, options, newState,fixedState);

				return fixedState;
			} else {
				return newState;
			}
		},
		function _getPathFromState(state, config) {
			let path = getPathFromState(state, config);
			path = path.replaceAll("%5Bobject%20Object%5D", "null");
			console.log("state >> url", state, config, path);
			return path;
		},
	];
}
