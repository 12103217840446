import React from "react";
import LinearGradient from "react-native-web-linear-gradient";
const bgShapeImage = require("../../assets/register_bg.png");
const appLogoSvg = require("../../assets/svg/app_logo.svg").default;
import { View, Image, ImageStyle } from "react-native";
import styles from "./styles";

export function Background({ children }: { children: any }) {
	return (
		<LinearGradient
			colors={["#8425F0", "#FF17C0"]}
			style={[styles.screen, { display: "block" }]}
			angle={45}
			useAngle
		>
			<Image
				source={{ uri: bgShapeImage }}
				resizeMode="cover"
				style={styles.imageBg as ImageStyle}
			/>
			<View
				style={{
					flex: 1,
					flexDirection: "column",
					marginTop: 70,
					alignItems: "center",
				}}
			>
				<Image source={{ uri: appLogoSvg, width: 78, height: 78 }} />
				{children}
			</View>
		</LinearGradient>
	);
}

type Fun = (_: any) => any;

type CheckboxProps = {
	checked?: boolean;
	onChangeValue?: undefined | Fun;
	nativeID?: any | undefined;
};
