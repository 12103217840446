import "./App.css";

import React from "react";

import { NavigationContainer } from "@react-navigation/native";
import { View } from "react-native";

import "./global_style.css";

import { navContainerLinkingConfig, defaultNavigatorFactory } from "./components/navigator";

import * as Login from "./components/loginView";
import { Datenschutz } from "./components/loginView/termsAndConditions";

const MyNavigator = defaultNavigatorFactory();

export const MaxWidth = 450;

export const RootRoutes = {
	login: {},
	Home: {},
	"journey/final": {},
};

function App() {
//

	React.useEffect(() => {
		console.log("--- mounted App ---");

		return () => console.log("--- UNmounted App ---");
	}, []);

	/** for list of the anonymous urls see profileContext.js  **/
	return (
		<View style={{ flex: 1, backgroundColor: "#FAD8F2" }}>
			<View style={{ flex: 1, maxWidth: MaxWidth, width: "100%", alignSelf: "center" }}>
				<NavigationContainer
					linking={navContainerLinkingConfig}
					onStateChange={(args) => {
						console.log("root nav container state change >>>", args);
					}}
				>
					<MyNavigator.Navigator
						id="Root"
						screenOptions={{
							// this is supposed to be disabled by default on web, but it still causes delay when switching unless explicit flag
							animationEnabled: false,
							headerShown: true,
							cardStyle: {
								backgroundColor: "green",
								minHeight: "800px", //not in %, breaks
								// this breaks it
								// flex:1
							},
							presentation: "card",
						}}
					>
						<MyNavigator.Screen
							name="login"
							component={Login.SplashScreen}
							options={{
								title: "Login",
								headerShown: false,
							}}
						/>

						<MyNavigator.Screen name="link-dispatch" component={Login.LinkDispatch} />

						<MyNavigator.Screen
							name="datenschutz"
							component={Datenschutz}
							options={{ title: "Datenschutzerklärung", headerShown: false }}
						/>
					</MyNavigator.Navigator>
				</NavigationContainer>
			</View>
		</View>
	);
}

App.RootRoutes = RootRoutes;

// js-jsx-mode
export default App;
